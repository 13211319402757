<template>
    <div class="_bWrap">

        <div class="_phoneMenu" v-if="!seen">
            <div class="_li" v-for="(item, index) in phoneMenu" :key="index" :class="{ p_active: p_current == index }"
                @click="$router.push({ path: item.path })">
                {{ item.text }}
            </div>

        </div>

        <div class="_b_auto">
            <h4></h4>
            <div class="_list">
                <div class="_li" v-for="(item, index) in list" :key="index">

                    <div class="_left">
                        <img :src="$IMG_URL + item.cover" alt="">
                    </div>
                    <div class="_right">
                        <div class="_h4">{{ item.title }}</div>
                        <div class="_p">{{ item.description }}</div>
                        <div class="_bgc">
                            <div class="_yf"><span>邮费</span> <span class="_r">{{ item.postage }}</span></div>
                            <div class="_xianxia">
                                <span>线下</span>
                                <span class="_r">{{ item.offline }}</span>
                            </div>
                            <div class="_tg">
                                <span>团购</span>
                                <span class="_r">{{ item.group_buy }}</span>
                            </div>
                        </div>

                        <!-- <div class="_shop" @click="buy(item)">请前往商城购买</div>  -->
                    </div>
                </div>
            </div>

            <div class="_page">
                <pagination v-show="total > 0" :total="total" :page.sync="queryParams.page"
                    :limit.sync="queryParams.page_num" @pagination="getList" />
            </div>
        </div>




        <fixIcon />
        <coment ref="comentRef"></coment>
        <mainFooter ref="mainFooterRef"></mainFooter>
    </div>
</template>

<script>
import { getDataById } from '../../api/getData';
export default {
    data() {
        return {
            p_current: 1,
            total: 0,
            list: [],
            queryParams: {
                page: 1,
                page_num: 6
            },
            seen: true,
            phoneMenu: [
                {
                    text: "参观服务",
                    path: "/exhibit"
                }, {
                    text: "精品文创",
                    path: "/boutique"
                }, {
                    text: "志愿者之家",
                    path: "/volHome"
                }
            ]
        }
    },
    mounted() {
        this.menuSeen()
        this.$scrollTop()
        this.getList()
    },
    methods: {
        menuSeen() {
            // 屏幕尺寸
            let screenHeight = document.body.clientWidth;
            if (screenHeight > 415) {
                this.seen = true;
            } else {
                this.seen = false;
            }
        },
        getList() {
            const params = {
                cate_id: 19,
                page: this.queryParams.page,
                page_num: this.queryParams.page_num,
                keywords: "",
                type_id: "",
                times: "",
            }
            getDataById(params).then(res => {
                console.log(res)
                this.list = res.data.data
                this.total = res.data.total
            })
        },
        buy(item) {
            window.open(item.shop_url)
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/common/mixin.scss";

._page
{
    text-align: center;
}

@mixin tit($url)
{
    width: 299px;
    height: 52px;
    background-image: url($url);
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

._bWrap
{
    margin-top: 200px;

    ._b_auto
    {
        width: 85%;
        margin: 0 auto;

        h4
        {
            @include tit("../../assets/images/h4-11.png");
            margin: 0 auto;
            margin-bottom: 60px;
        }
    }

}


._list
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    ._li
    {
        display: flex;

        cursor: pointer;
        width: 48%;
        margin-bottom: 70px;

        ._left
        {
            // width: 300px;
            width: 40%;
            height: 300px;
            overflow: hidden;

            img
            {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: all .5s;
            }

            img:hover
            {
                transform: scale(1.1);
            }
        }

        ._right
        {
            margin-left: 20px;
            width: 60%;

            ._h4
            {
                font-size: 24px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                font-weight: bold;
            }

            ._p
            {
                color: #666;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                /* 设置最大显示行数 */
                -webkit-box-orient: vertical;
                text-overflow: ellipsis;
                margin: 10px 0;
                font-size: 15px;
            }

            ._bgc
            {
                background-color: #fff;
                padding: 18px 20px;

                span
                {
                    color: #666;

                }

                ._r
                {
                    color: #333;
                    margin-left: 40px;
                }

                ._yf
                {
                    border-bottom: 1px dashed #ccc;
                    padding-bottom: 14px;
                }

                ._xianxia
                {
                    margin: 14px 0;
                }
            }

            ._shop
            {
                @include tit("../../assets/images/gm.png");
                text-align: center;
                line-height: 47px;
                text-align: center;
                color: white;
                font-size: 20px;
                margin-top: 20px;
            }
        }
    }
}

._phoneMenu
{
    // @include respondTo('phone'){

    // }

    display: flex;
    width: 100%;
    background-color: #3D4044;
    height: 50px;
    justify-content: space-around;
    align-items: center;
    color: white;

    .p_active
    {
        color: #F9DAA0;
    }
}

._bWrap
{
    width: 100%;
    overflow: hidden;
    @include respondTo('phone')
    {
        margin-top: 60px;


        ._b_auto
        {
            width: 95%; 
            >h4
            {
                background-image: url("../../assets/images/phone/jpwctit.png");

                width: 173px;
                height: 30px;
                margin-bottom: 27px;
                margin-top: 20px;
            }
        }

        ._list{
            display: block;
            ._li{
                width: 100%;
                display: block;
                margin-bottom: 32px;
                ._left{
                    width: 100%;
                    border: 1px dashed #E9E9E9;
                }
                ._right{
                    width: 100%;
                    margin-left: 0;
                    ._h4{
                        text-align: center;
                        margin-top: 10px;
                    }
                    ._p{
                        display: none;
                    }
                    ._bgc{
                        display: none;
                    }
                    ._shop{
                        display: none;
                    }
                }
            }
        }
    }
}
</style>